import React, { useState, useEffect } from 'react';
import config from '../../config';
import { NavLink, useLocation } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import './style.scss';
import { useOktaAuth } from '@okta/okta-react';
import User from '../user/User';
import EmployeeService from '../../core/services/EmployeeService';
import ProfilePhoto from '../profilePhoto/ProfilePhoto';

export default function Navigation() {
  const { authState, oktaAuth } = useOktaAuth();
  const [employee, setEmployee] = useState(null);

  // if we don't clear tokens before redirect we get an AuthApiError on logout in Chromium browsers
  // there _is_ a slightly ominous warning here though: https://github.com/okta/okta-auth-js#signout
  const logout = async () => await oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/`, clearTokensBeforeRedirect: true });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      EmployeeService.getCurrentEmployee()
        .then(emp => {
          setEmployee(emp);
        })
        .catch(_ => {
          // setProfileImageUrl(defaultProfileImageUrl);
        })
    } else {
    }
  }, [authState])

  // Dynamically update the site <title>
  const location = useLocation();
  useEffect(() => {
    let siteTitle = config.site.name; // base name from the config file
    // we leave the title as-is (i.e. we set nothing) if we are on the home page
    if (location.pathname === '/directory') {
      siteTitle += ' - StoryMaker Directory';
    } else if (location.pathname === '/seatingmaps') {
      siteTitle += ' - Experience Hubs';
    } else if (location.pathname === '/surveydashboard') {
      siteTitle += ' - Survey Dashboard';
    } else if (location.pathname.startsWith('/survey') || location.pathname.startsWith('/introquestions')) {
      siteTitle += ' - Survey';
    } else if (location.pathname === '/profile') {
      siteTitle += ' - Profile';
    }
    document.title = siteTitle; // update the page <title>
  }, [location]);

  // if we aren't authenticated, don't show the nav
  if (!authState || !authState.isAuthenticated) {
    return null;
  }
  else if (authState && authState.isAuthenticated) {
    return (
      <div className="appbar">

        <AppBar position="static">
          <Toolbar>
            <NavLink to="/"><span className="appTitle">{config.site.name}</span></NavLink>
            <ul className="menuContainer">
              <li className="menuButton"><MenuIcon className="menuIcon" />
                <ul className="menuList">
                  {/* VAN-110 we don't want the Home nav item to show when on the home page. no active class name because it'll never be active */}
                  <li><NavLink activeClassName="current" exact to="/">Home</NavLink></li>
                  <li><NavLink activeClassName="current" to="/directory">Directory</NavLink></li>
                  <li><NavLink activeClassName="current" to="/seatingmaps">Hubs</NavLink></li>
                  <li><NavLink activeClassName="current" to="/storymakermap">StoryMaker Map</NavLink></li>
                  <li><NavLink activeClassName="current" to={{pathname: "https://storymakercentral.omceg.com/who-to-ask/"}} target="_blank">Who To Ask</NavLink></li>
                </ul>
              </li>
            </ul>&nbsp;&nbsp;
              {/* if the auth isPending, display a note rather than our avatar and menu items */}
            {authState.isPending
              ? <div>Loading authentication...</div>
              : (<div>
                <User clickHandler={handleMenu} />
                <Button
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  className="accountButton"
                  onClick={handleMenu}
                >
                  <ProfilePhoto employee={employee} forceRefresh={true} />
                </Button>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <NavLink to="/profile">Your Profile</NavLink>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <NavLink to="#" onClick={logout}>Log Out</NavLink>
                  </MenuItem>
                </Menu>
              </div>)
            }

          </Toolbar>
        </AppBar>
      </div>
    );
  }
}